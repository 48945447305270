import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { TypeAnimation } from "react-type-animation";

export default function HeroSection() {
  return (
    <Container fluid id="home" className="">
      <Row className="hero justify-content-center">
        <Col className="container-default">
          <Row>
            <Col>
              <p className="hero--pre-title">Hello, world!</p>
              <p className="hero--title">
                <span className="color-pink">const</span>{" "}
                <span className="color-orange">
                  <i>name</i>
                </span>{" "}
                <span className="color-blue">=</span>{" "}
                <span>
                  <TypeAnimation
                    sequence={[
                      '"Dayana Sánchez";',
                      3000,
                      ,
                      '"Daily San";',
                      3000,
                      ,
                    ]}
                    className="typing"
                    speed={{ type: "keyStrokeDelayInMs", value: 150 }}
                    deletionSpeed={40}
                    repeat={Infinity}
                  />
                </span>
              </p>
              <p className="hero--description">
                I'm a <span>FullStack Developer</span> proficient in{" "}
                <span>Angular, React, Vue, NestJS, and Node.js</span>.
                Experienced in both relational and non-relational{" "}
                <span>databases</span>.
              </p>
              <Row>
                <Col>
                  <Button href="#about" className="btn-link color-green me-5">
                    about me
                  </Button>
                  <Button href="#projects" className="btn-link color-default">
                    projects
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
