import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import data from "../../data/index.json";
import { Github } from "react-bootstrap-icons";

export default function Projects() {
  return (
    <Container fluid id="projects">
      <Row className="projects justify-content-center">
        <Col className="container-default">
          <Row>
            <Col>
              <h2 className="title">
                <span>{"{"}</span>featured_projects<span>{"}"}</span>
              </h2>
              <p>
                I have worked on many projects over the course of being a
                FullStack Developer, here are a few of them.
              </p>
              {data.projects.map((project, index) => (
                <Col key={index}>
                  <Row className="project--container">
                    <Col md={6} className="mt-sm-5 mb-sm-4">
                      <div className="project--image">
                        <img src={project.imgSrc} />
                      </div>
                    </Col>
                    <Col md={6} className="project--info">
                      <div className="project--title">{project.title}</div>
                      <div className="project--client">
                        <span className="color-orange">
                          <i>clientName</i>
                        </span>{" "}
                        <span className="color-blue">=</span> {project.client}
                      </div>
                      <div className="project--description mb-4">
                        {project.description}
                      </div>
                      <div className="project--tools mb-4">
                        {project.tools.join(", ")}
                      </div>

                      {project.link && (
                        <Button
                          target="_blank"
                          href={project.link}
                          className="btn-link color-blue"
                        >
                          See it on Github
                          <Github className="ms-2" />
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Col>
              ))}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
