import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import data from "../../data/index.json";

export default function AboutMe() {
  const { floor } = Math;
  const initialDate = new Date("2016-07-16");
  const today = new Date();
  const diff = today - initialDate;
  const days = floor(diff / (1000 * 60 * 60 * 24));
  const years = floor(days / 365);
  const monhs = floor((days % 365) / 30);

  return (
    <Container fluid id="about">
      <Row className="aboutme justify-content-center">
        <Col className="container-default">
          <Row>
            <Col>
              <h2 className="title">
                <span>{"{"}</span>about_me<span>{"}"}</span>
              </h2>
              <p>
                My name is Dayana Sanchez, a passionate Full Stack Developer.
                I've worked across diverse industries, including banking,
                airlines, and software factories, gaining versatile expertise. I
                enjoy creating seamless experiences on both the front and back
                end, making me fluent in a wide range of technologies.
              </p>

              <div className="experience">
                <span className="color-pink">let</span>{" "}
                <span className="color-orange">
                  <i>myExperience</i>
                </span>{" "}
                <span className="color-blue">={" "}</span><p className="space"></p>
                <span className="color-blue">{"`$"}</span>
                <span className="color-purple">{"{"}</span>
                <span>years: </span>
                <span className="color-orange">{years}</span>
                <span>, months: </span>
                <span className="color-orange">{monhs}</span>
                <span className="color-purple">{"}"}</span>
                <span className="color-blue">{"`"}</span>;
              </div>

              <p>
                Specialised in building mobile and web apps, while ensuring a
                great web experience for end users.
              </p>

              <div className="tools-container">
                {data.tools.map((tool, index) => (
                  <div className="tool" key={index}>
                    <div dangerouslySetInnerHTML={{ __html: tool.svg }} />
                    <span>{tool.title}</span>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
