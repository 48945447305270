import Contact from "../Contact";
import HeroSection from "../HeroSection";
import Navigation from "../Navigation";
import Projects from "../Projects";
import AboutMe from "../AboutMe";
import CursorEffect from "../CursorEffect";

export default function Home() {
  return (
    <>
    <CursorEffect />
      <Navigation />
      <HeroSection />
      <AboutMe />
      <Projects />
      <Contact />
    </>
  );
}
