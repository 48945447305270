import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function Contact() {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);
  const [isSendingForm, setIsSendingForm] = useState(false);

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSendingForm(true);

    try {
      const response = await fetch("https://dailysan.dev/ftools/process.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          form_tools_form_id: "2",
          name,
          email,
          message,
        }),
      });

      if (response.ok) {
        setIsSendingForm(false);
        setFormSubmitted(true);
        setName("");
        setEmail("");
        setMessage("");
      } else {
        setIsSendingForm(false);
        setFormError(true);
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  };

  return (
    <Container fluid id="contact">
      <Row className="contact justify-content-center">
        <Col className="container-default">
          <Row>
            <Col md={12}>
              <h2 className="title">
                <span>{"{"}</span>contact_me<span>{"}"}</span>
              </h2>
            </Col>
            <Col md={6} className="pe-md-5">
              <p className="mb-5">Let’s work together!</p>
              <p className="mb-5">
                You can say hello at{" "}
                <a href="mailto:contact@dailysan.dev">contact@dailysan.dev</a>{" "}
                or fill the form to contact me
              </p>
            </Col>
            <Col md={6}>
              <Form onSubmit={handleSubmit}>
                <input type="hidden" name="form_tools_form_id" value="2" />
                <Form.Control
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  type="text"
                  placeholder="Name"
                  required
                  className={`mb-3 ${name && "has-data"}`}
                  name="name"
                  value={name}
                />
                <Form.Control
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  type="email"
                  placeholder="Email"
                  required
                  className={`mb-3 ${
                    email && isEmailValid(email) && "has-data"
                  }`}
                  name="email"
                  value={email}
                />
                <Form.Control
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  as="textarea"
                  placeholder="How can I help you?"
                  required
                  rows={3}
                  className={`mb-4 ${message && "has-data"}`}
                  name="message"
                  value={message}
                />
                <Button
                  disabled={isSendingForm}
                  className="btn-link color-blue btn-submit"
                  type="submit"
                >
                  {isSendingForm ? "Sending..." : "Send message"}
                </Button>
              </Form>

              {formSubmitted && (
                <div className="form-submited success">
                  <p>Thank you for getting in touch!</p>
                  <p>I will get back to you shortly.</p>
                  <p>Have a great day!</p>
                </div>
              )}
              {formError && (
                <div className="form-submited error">
                  <p>Something went wrong when sending your message :{"("}</p>
                  <p>Please try again.</p>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
