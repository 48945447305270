import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Github } from "react-bootstrap-icons";
import Scrollspy from "react-scrollspy";

export default function Navigation() {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleNavLinkClick = () => {
    setMenuOpen(false);
  };

  return (
    <Navbar bg="dark" expand="md" fixed="top" className="navbar">
      <Container className="container-default">
        <Navbar.Brand className="navbrand" href="#home">
          <img
            alt=""
            src="/img/logo.svg"
            width="193"
            className="d-inline-block"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md}`} onClick={handleToggleMenu} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-md`}
          aria-labelledby={`offcanvasNavbarLabel-expand-md`}
          placement="end"
          show={menuOpen}
        >
          <Offcanvas.Header onClick={handleToggleMenu} closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
              <img
                alt=""
                src="/img/logo.svg"
                width="193"
                className="d-inline-block"
              />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Scrollspy
              items={["home", "about", "projects", "contact"]}
              currentClassName="active"
              offset={-50}
              componentTag="nav"
              className="navbar-nav justify-content-end flex-grow-1"
            >
              <Nav.Link onClick={handleNavLinkClick} href="#home">
                <span>{"{"}</span>home<span>{"}"}</span>
              </Nav.Link>
              <Nav.Link onClick={handleNavLinkClick} href="#about">
                <span>{"{"}</span>about_me<span>{"}"}</span>
              </Nav.Link>
              <Nav.Link onClick={handleNavLinkClick} href="#projects">
                <span>{"{"}</span>projects<span>{"}"}</span>
              </Nav.Link>
              <Nav.Link onClick={handleNavLinkClick} href="#contact">
                <span>{"{"}</span>contact_me<span>{"}"}</span>
              </Nav.Link>
              <Nav.Link
                className="github ms-sm-2 ms-md-0"
                href="https://github.com/dayanalily/"
                target="_blank"
              >
                <Github />
              </Nav.Link>
            </Scrollspy>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}
